<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" style="margin-bottom:0">
      <cube-scroll v-if="item">
        <div class="mobile-detail-box" style="padding-bottom:0">
          <div class="mobile-detail-row">
            <div class="mobile-detail-label">计划日期</div>
            <div class="mobile-detail-value">{{formatDate(item.time)}}</div>
          </div>
          <div class="mobile-detail-row">
            <div class="mobile-detail-label">相关项目</div>
            <div class="mobile-detail-value">{{item.project_name}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==1">
            <div class="mobile-detail-label">计划内容</div>
            <div class="mobile-detail-value" v-html="getHtml(item.cause)"></div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==1">
            <div class="mobile-detail-label">计划状态</div>
            <div class="mobile-detail-value">{{getDictText(itemState,item.state)}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==0">
            <div class="mobile-detail-label">拜访客户</div>
            <div class="mobile-detail-value">{{item.customer_name}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==0">
            <div class="mobile-detail-label">拜访事由</div>
            <div class="mobile-detail-value" v-html="getHtml(item.cause)"></div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==0">
            <div class="mobile-detail-label">拜访状态</div>
            <div class="mobile-detail-value">{{getDictText(itemState,item.state)}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==0 && (item.state==1 || item.state==2)">
            <div class="mobile-detail-label">到达时间</div>
            <div class="mobile-detail-value">{{formatDate(item.fact_time,'yyyy-MM-dd hh:mm')}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.category==0 && (item.state==1 || item.state==2)">
            <div class="mobile-detail-label">到达位置</div>
            <div class="mobile-detail-value" @click="clickAddress">{{item.fact_address}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.state==3">
            <div class="mobile-detail-label">取消时间</div>
            <div class="mobile-detail-value">{{formatDate(item.finish_time,'yyyy-MM-dd hh:mm')}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.state==3">
            <div class="mobile-detail-label">取消原因</div>
            <div class="mobile-detail-value" v-html="getHtml(item.finish_memo)"></div>
          </div>
          <div class="mobile-detail-row" v-if="item.state==2">
            <div class="mobile-detail-label">完成时间</div>
            <div class="mobile-detail-value">{{formatDate(item.finish_time,'yyyy-MM-dd hh:mm')}}</div>
          </div>
          <div class="mobile-detail-row" v-if="item.state==2">
            <div class="mobile-detail-label">完成备注</div>
            <div class="mobile-detail-value" v-html="getHtml(item.finish_memo)"></div>
          </div>
          <comment :id="item.id" order_name="工作计划"></comment>
          <!-- <div class="item-button-box" v-if="allowEdit && item.category==0 && item.state==0">
            <div class="item-button button-bg2" @click="cancelItem()">取消</div>
            <div class="item-button button-bg1" @click="arriveItem()">到达</div>
          </div>
          <div class="item-button-box" v-if="allowEdit && item.category==0 && item.state==1">
            <div class="item-button button-bg1" @click="finishItem()">完成</div>
          </div>
          <div class="item-button-box" v-if="allowEdit && item.category==1 && item.state==0">
            <div class="item-button button-bg2" @click="cancelItem()">取消</div>
            <div class="item-button button-bg1" @click="finishItem()">完成</div>
          </div> -->
        </div>
        <Modal v-model="showFinishMemo" :title="newFinishTitle" @on-ok='finishVisitPlanItem'>
          <textarea v-model="newFinishMemo" class="full-line default-font-color" style="height:220px;border: 0;background-color: #EFEFEF;padding: 10px;resize: none;border-radius:5px;"></textarea>
        </Modal>
      </cube-scroll>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/format.js";
import { wgs84ToGcj02 } from "@/utils/wgs84.js";
import { getDictText, visitPlanState } from "@/utils/dict.js";
import { GetVisitPlanItem, FinishVisitPlanItem, ArriveVisitPlanItem } from "@/api";
import { loadDetailAction, saveAction, submitAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      item: null,
      reply: null,
      content: "",
      itemState: visitPlanState,
      allowEdit: false,
      showFinishMemo: false,
      newFinishTitle: "",
      newFinishMemo: "",
      newFinishState: false
    }
  },
  methods: {
    formatDate: formatDate,
    getDictText: getDictText,
    getHtml: function (text) {
      return text ? text.replace(/\n/g, '<br/>') : "";
    },
    gotoDetail: function () {
      this.$router.push('/mobile/office/visitplandetail?id=' + this.item.pid);
    },
    clickAddress: function () {
      if (this.item.fact_longitude && this.item.fact_latitude) {
        window.open("https://nav.kdainfo.com/openlocationoa.html?lng=" + this.item.fact_longitude + "&lat=" + this.item.fact_latitude, "_blank");
      }
    },
    arriveItem: function () {
      submitAction(this, ArriveVisitPlanItem, { id: this.id, longitude: this.longitude, latitude: this.latitude }, () => { this.dataRequest(); }, null, true, "是否确定到达？");
    },
    cancelItem: function () {
      this.showFinishMemo = true;
      this.newFinishTitle = "请输入取消原因";
      this.newFinishMemo = "";
      this.newFinishState = false;
    },
    finishItem: function () {
      this.showFinishMemo = true;
      this.newFinishTitle = "请输入完成结果";
      this.newFinishMemo = "";
      this.newFinishState = true;
    },
    finishVisitPlanItem: function () {
      saveAction(this, FinishVisitPlanItem, { id: this.newFinishId, finish: this.newFinishState, memo: this.newFinishMemo, longitude: this.longitude, latitude: this.latitude }, () => { this.dataRequest(); });
    },
    setPosition: function (position) {
      var transformLnglat = wgs84ToGcj02(position.coords.longitude, position.coords.latitude);
      this.longitude = transformLnglat[0];
      this.latitude = transformLnglat[1];
    },
    dataRequest: function () {
      loadDetailAction(this, GetVisitPlanItem, { id: this.id }, (data) => {
        this.item = data.item;
        this.reply = data.reply;
        this.content = "";
        this.allowEdit = data.allowEdit;
      });
    },
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setPosition);
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  }
}
</script>